import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { apiRouterCall } from "src/ApiConfig/service";
import { AuthContext } from "src/context/Auth";
import { capitalizeFirstLetter, checkImage } from "src/utils";
import { Typography } from "@material-ui/core";

function SpinResultModal({
  setSpinResultModalOpen,
  finalValue,
  setFinalValue,
  spinId,
  setSpinId,
}) {
  const auth = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const claimNftReward = async () => {
    try {
      setIsLoading(true);
      const response = await apiRouterCall({
        method: "POST",
        endPoint: "claimNft",
        bodyData: { _id: spinId },
      });
      if (response.data.responseCode === 200) {
        toast.success(response.data.message);
        setFinalValue("");
        setSpinId("");
        setSpinResultModalOpen(false);
      } else {
        toast.error(response.data.message);
      }

      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="spin-result-modal p-4 flex justify-center items-center overflow-hidden">
      <div>
        <img
          src={
            finalValue && finalValue !== "Better Luck!"
              ? "/images/games/Congratulations.png"
              : "/images/games/OppsImg.png"
          }
          alt="Spinner"
          className="text-design"
        />
      </div>

      <Typography variant="h3">
        {finalValue && finalValue !== "Better Luck!"
          ? `You Won A ${capitalizeFirstLetter(finalValue)}`
          : "Better Luck Next Time!"}
      </Typography>

      <div>
        <img
          style={{
            height: "232px",
            borderRadius: "10px",
            marginTop: "20px",
            marginBottom: "20px",
          }}
          src={
            finalValue && finalValue !== "Better Luck!"
              ? checkImage(finalValue)
              : "/images/games/BetterLuckNextTime.png"
          }
        />
      </div>
      {finalValue && finalValue !== "Better Luck!" ? (
        <div style={{ marginTop: "10px" }}>
          <span className="originalFierovalue">
            Original Cost - {auth?.perNftPrice || 0} Fiero
          </span>
        </div>
      ) : (
        <span className="almuniText">Free Spin Available Tomorrow</span>
      )}
      <button
        className="spin-cutumButton displayCenter"
        onClick={() => {
          if (!isLoading) {
            if (finalValue && finalValue !== "Better Luck!") {
              claimNftReward();
            } else {
              setFinalValue("");
              setSpinId("");
              setSpinResultModalOpen(false);
            }
          }
        }}
      >
        <span className="button-text">
          {isLoading
            ? "Loading..."
            : finalValue && finalValue !== "Better Luck!"
            ? "Claim Now"
            : "Spin Again"}
        </span>
      </button>
    </div>
  );
}

export default SpinResultModal;
